* {
    /* font-family: "Poppins" */
}



.lightMode {
    background-color: #F8F5EF;
    /* color: #fef8f1c7; */
    /* color: black; */
    transition: background-color 0.5s ease, color 0.5s ease;
}

.darkMode {
    background-color: #292929;
    /* color: white; */
    transition: background-color 0.5s ease, color 0.5s ease;
}

.footer-transition {
    transition: background-color 0.5s ease, color 0.5s ease;
}

.frame {
    /* margin: 0px 150px 0px 150px; */
}

.fade-on-load {
    animation: fade-in 1s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

.header-class {
    position: fixed !important;
    width: 100%;
}

/* FONT SECTION */
.rajdhani-light {
    font-family: "Rajdhani", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.rajdhani-regular {
    font-family: "Rajdhani", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.rajdhani-medium {
    font-family: "Rajdhani", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.rajdhani-semibold {
    font-family: "Rajdhani", sans-serif;
    font-weight: 600;
    font-style: normal;
}


.rajdhani-bold {
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-style: normal;
}
